import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import ProductsCategoriesContainer from '@/containers/CategoriesProduct/ProductsCategoriesContainer';

import { AllPrismicProductQuery } from '../../graphql-types';

interface Props {
  data: AllPrismicProductQuery;
  pageContext: {
    name: {
      text: string;
    };
  };
}

export const query = graphql`
  query ProductCategories($uid: String!) {
    allPrismicProduct(
      filter: {
        data: {
          all_categories: { elemMatch: { category: { uid: { eq: $uid } } } }
        }
      }
    ) {
      edges {
        node {
          id
          uid
          data {
            image {
              url
              alt
            }
            name {
              raw
            }
            description {
              raw
            }
            composition
            liter
          }
        }
      }
    }
  }
`;

const ProductsCategoriesTemplate = ({
  data,
  pageContext,
}: Props): JSX.Element => {
  const CategoryName = pageContext?.name;
  return (
    <Layout invertNav type="magasin">
      <Seo title="Article" description="Article" />
      <ProductsCategoriesContainer data={data} name={CategoryName} />
    </Layout>
  );
};

export default ProductsCategoriesTemplate;
