import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';
import ProductThumbnail from '@/components/molecules/ProductThumbnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import mq from '@/styles/mq';

import { ProductThumbnailType } from '@/domain/entities/Product';

interface CategoriesProductsProps {
  products?: ProductThumbnailType[];
  categoryName?: string;
}

const CategoriesProductsRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(10)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;
const ProductGrid = styled(Grid)`
  display: flex;
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(3.5)};
  padding-right: ${({ theme }) => theme.spacing(3.5)};
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;
const ProductsContainer = styled(Grid)`
  align-items: stretch;
  margin-top: ${({ theme }) => theme.spacing(2)};
  & ${ProductGrid} {
    ${mq('md')} {
      border-right: 2px solid ${({ theme }) => theme.color.secondary.main};
    }
  }

  & ${ProductGrid}:nth-of-type(2n) {
    ${mq('md')} {
      border-right: none;
    }

    ${mq('lg')} {
      border-right: 2px solid ${({ theme }) => theme.color.secondary.main};
    }
  }

  & ${ProductGrid}:nth-of-type(3n) {
    ${mq('lg')} {
      border-right: none;
    }
  }

  & ${ProductGrid}:nth-of-type(n+3) {
    ${mq('md')} {
      border-top: 2px solid ${({ theme }) => theme.color.secondary.main};
    }

    ${mq('lg')} {
      border-top: none;
    }
  }

  & ${ProductGrid}:nth-of-type(n+4) {
    ${mq('lg')} {
      border-top: 2px solid ${({ theme }) => theme.color.secondary.main};
    }
  }
`;
const Title = styled(Typography)`
  /* margin-top: ${({ theme }) => theme.spacing(10)}; */
`;
const CategoryTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  /* margin-left: ${({ theme }) => theme.spacing(10)}; */
  /* margin-bottom: ${({ theme }) => theme.spacing(10)}; */
  ${mq('xxs')} {
    margin-top: ${({ theme }) => theme.spacing(0)};
  }
  text-align: center;
`;
const BackButton = styled.button`
  background-color: transparent;
  /* position: absolute; */
  /* top: 50px; */
  left: center;
  margin-right: ${({ theme }) => theme.spacing(3)};
  ${mq('lg')} {
    /* position: initial; */
    /* left: -100px; */
    /* top: 20px; */
  }
`;
const CategoriesBoxesHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(10)};
  ${mq('xxs')} {
    flex-direction: row;
  }
`;
const CategoriesProducts = ({
  products,
  categoryName,
}: CategoriesProductsProps): JSX.Element => {
  return (
    <CategoriesProductsRoot maxWidth="lg">
      <CategoriesBoxesHeader>
        <BackButton onClick={() => navigate(-1)}>
          <FontAwesomeIcon size="2x" icon={faArrowLeft} />
        </BackButton>
        {products?.length !== 0 && (
          <CategoryTitle variant="h1">{categoryName}</CategoryTitle>
        )}
        {products?.length === 0 && (
          <Title variant="h2">Pas de produits pour cette catégorie</Title>
        )}
      </CategoriesBoxesHeader>
      <ProductsContainer container>
        {products !== undefined &&
          products?.length > 0 &&
          products?.map((product: ProductThumbnailType, i: number) => (
            <ProductGrid key={i.toString()} xxs={12} md={6} lg={4}>
              <ProductThumbnail {...product} />
            </ProductGrid>
          ))}
      </ProductsContainer>
    </CategoriesProductsRoot>
  );
};

export default CategoriesProducts;
