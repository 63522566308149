import React from 'react';

import CategoriesProducts from '@/containers/CategoriesProduct/CategoriesProducts';

import formatProductsThumbnails from '@/utils/formatProductsThumbnails';
import { ProductTemplateQuery } from '../../../graphql-types';

interface Props {
  data: ProductTemplateQuery;
  name: {
    text: string;
  };
}

const ProductsCategoriesContainer = ({ data, name }: Props): JSX.Element => {
  const products = formatProductsThumbnails(data?.allPrismicProduct?.edges);
  const CategoriesProductsProps = {
    products,
  };
  return (
    <>
      <CategoriesProducts
        {...CategoriesProductsProps}
        categoryName={name?.text}
      />
    </>
  );
};

export default ProductsCategoriesContainer;
